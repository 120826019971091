@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Poppins";
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}
.topmainheading{
  @apply text-white text-base font-serif;
}
.topheading{
  @apply text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-serif;
}
.textheading{
  @apply text-black font-bold text-3xl;
}
.subtextheading{
  @apply text-black text-2xl font-serif;
}
.heading{
  @apply text-white text-2xl;
}
.subheading{
  @apply  text-lg text-gray-100;
}
.textparagraph{
  @apply  text-sm text-gray-800;
}
.paragraph{
  @apply  text-base text-gray-400;
}